export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.logoLocationContainer': {
      // display: 'none',
      padding: '0rem'
    },
    '.logo': {
      // display: 'none',
      a: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      img: {
        maxHeight: ['110px', '120px', '130px', '145px', '160px'],
        // filter: 'brightness(0) invert(1)',
        // maxWidth: ['', '', 'unset', 'unset'],
        filter: 'unset'
      }
    },
    '.logoScrolled': {
      // display: 'none',
      img: {
        maxHeight: ['60px', '70px', '70px', '70px', '70px'],
        filter: 'unset'
      }
    },

    '.smallNavMenu .navItemDropdownMenu': {
      backgroundColor: 'black'
    },

    '.container': {
      padding: ['1rem', '1.5rem'],
      position: 'absolute',
      backgroundColor: 'transparent',
      top: '0rem',
      '.smallNavMenu': {
        // margin: '0rem auto 0rem 2rem',
        '.navItem': {
          textShadow: '1px 1px 10px black',
          color: 'white',
          a: {
            fontSize: '1rem',
            ':hover': {
              textDecoration: 'underline',
              color: 'white'
            }
          }
        },
        '.react-reveal': {
          opacity: '1'
        }
      }
    },
    '.containerScrolled': {
      border: 'none',
      padding: '0.5rem 1rem',
      backgroundColor: '#060506',
      // width: 'fit-content',
      position: 'fixed',
      right: '0rem',
      top: '0rem',
      left: 'unset',
      borderBottom: '1px solid',
      borderColor: 'primary',
      '.smallNavMenu': {
        // margin: '0rem auto 0rem 2rem',
        '.navItem': {
          textShadow: '1px 1px 10px black',
          color: 'white',
          a: {
            fontSize: '1rem',
            ':hover': {
              textDecoration: 'underline',
              color: 'lightgrey'
            }
          }
        },
        '.react-reveal': {
          opacity: '1'
        }
      }
    },
    '.hamburger': {
      borderRadius: '100px',
      padding: '0.75rem',
      borderColor: 'primary',
      backgroundColor: 'rgba(255,255,255, 0.6)',
      display: ['', '', '', 'none'],
      '> div': {
        backgroundColor: 'primary'
      }
    },

    '.phoneContainer': {
      display: 'flex',
      border: 'none',
      margin: '0rem 1rem',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      a: {
        backgroundColor: 'primary',
        padding: '0.5rem',
        color: 'light',
        textAlign: 'center',
        margin: '0.5rem 0rem'
      }
    },
    '.hamburgerOpen': {
      borderColor: 'white',
      '> div': {
        backgroundColor: 'light'
      }
    },
    '.navMenuLogo': {
      display: 'none'
    },
    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],
      width: '80%',
      maxWidth: '500px',
      backgroundColor: 'primary',
      '.navItem': {
        a: {
          color: 'light',
          ':hover': {
            textDecoration: 'underline'
          }
        }
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right'],
        color: 'white'
      },

      '.socialIconsContainer': {
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
          svg: {
            path: {
              fill: 'white'
            }
          }
        }
      }
    },

    '.navBlock': {
      right: 'unset',
      top: '200vh'
    }
  },

  footer: {
    backgroundColor: 'background',
    borderTop: 'solid 5px',
    borderColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['0.5rem 0.5rem 6rem', '', '0.5rem 3rem'],
    '.image': {
      // filter: 'brightness(0) invert(1)',
      display: 'none'
    },
    '.multiButtonContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      a: {
        width: 'fit-content',
        fontFamily: 'heading'
      }
    },
    '.contactDetails-container': {
      display: 'none'
    },
    '.copyright': {
      width: '50%',
      color: 'white',
      textAlign: 'center'
    },
    '.socialIconsContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '1rem',
      a: {
        svg: {
          width: '40px',
          height: '40px',
          padding: '0.5rem',
          border: 'solid 1px white',
          borderRadius: '100px',
          ':hover': {
            backgroundColor: 'grey'
          }
        },
        margin: '0.5rem'
      }
    },
    '.gonationLogo': {
      width: '50%'
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2.5rem', '3rem', '4rem', '4.5rem', '4.5rem'],
    fontWeight: 'normal',
    letterSpacing: '4px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'inherit',
    marginBottom: '1.5rem',
    textTransform: 'uppercase',
    fontFamily: 'heading',
    color: 'inherit',
    color: 'black',
    textShadow: [
      '-2.5px -2.5px 0 #fdfdfd, 2.5px -2.5px 0 #fdfdfd, -2.5px 2.5px 0 #fdfdfd, 2.5px 2.5px 0 #fdfdfd',
      '',
      '-5px -5px 0 #fdfdfd, 5px -5px 0 #fdfdfd, -5px 5px 0 #fdfdfd, 5px 5px 0 #fdfdfd'
    ]
  },
  subtitle: {
    width: 'fit-content',
    textAlign: 'inherit',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.5rem', '1.75rem', '2rem', '2rem'],
    marginBottom: '1.5rem',
    border: 'none',
    color: 'inherit',
    fontFamily: 'subheading',
    fontWeight: '200'
  },
  text: {
    p: {
      color: 'inherit',
      lineHeight: '1.75',
      fontSize: 'inherit',
      textAlign: 'center'
    }
  },

  sideBySide1: {
    backgroundColor: 'background',
    padding: ['1rem', '', '2rem', '3rem'],
    '.lazyload-wrapper': {
      minHeight: ['', '', '50vh'],
      maxHeight: ['', '', '80vh'],
      justifyContent: 'flex-start',
      img: {
        border: 'solid 10px white'
      }
    },

    '.content': {
      position: 'relative',
      alignItems: 'flex-start',
      margin: ['1rem 0rem', '', '0rem'],
      padding: ['2rem 1rem', '2rem', '3rem', '3rem', '4rem']
    },
    '.title': {
      variant: 'customVariants.title'
      // '::before': {
      //   content: "'⫛'",
      //   color: 'primary',
      //   display: 'flex',
      //   flexDirection: 'column',
      //   justifyContent: 'center',
      //   alignItems: 'center',
      //   fontSize: '4rem',
      //   margin: '0rem 2rem 0rem 0rem',
      //   transform: 'rotate(-180deg)',
      // },
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text',
      p: {
        textAlign: 'left'
      }
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      marginBottom: '0rem'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1'
  },

  centerBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '90vh',
    color: 'white',
    textAlign: 'center',
    // '::before': {
    //   content: "'🍋'",
    //   color: 'primary',
    //   display: 'flex',
    //   flexDirection: 'column',
    //   justifyContent: 'center',
    //   alignItems: 'center',
    //   fontSize: '4rem',
    //   margin: '0rem 2rem 2rem',
    //   // transform: 'rotate(-180deg)',
    // },
    padding: ['6rem 1rem', '8rem 1rem'],
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },

    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  centerBlock2: {
    variant: 'customVariants.centerBlock',
    background: 'none',
    height: 'auto'
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    height: '100vh',
    'slick-slider': {
      img: {
        filter: ['brightness(0.4)', '', 'unset'],
        objectPosition: ['right', '', 'center']
      }
    },

    '.hero_content_container': {
      // maxWidth: ['', '', '50%'],
      left: ['0%'],
      marginLeft: '0rem',
      paddingLeft: ['', '', '', '35%'],
      paddingRight: ['', '', '', '5rem'],
      width: '100%',

      backgroundColor: 'black',
      top: 'unset',
      bottom: '0%',
      maxWidth: 'unset',
      transform: 'unset',
      // '> *': {
      //   background: '#b0b9cb',
      //   boxShadow: '0px 0px 20px 20px #b0b9cb',
      // },
      '> *': {
        maxWidth: ['', '', '', '65%']
      },

      a: {
        position: ['', '', '', 'absolute'],
        right: ['', '', '', '6rem'],
        top: ['', '', '', '50%'],
        transform: ['', '', '', 'translateY(-50%)']
      }
    },
    '.logoHero': {
      display: ['none', '', '', 'block'],
      position: 'absolute',
      left: ['2.5%'],
      bottom: '5%'
      // borderRadius: '500px',
      // background: '#ffffffc7 !important',
      // boxShadow: '0px 0px 20px 20px #ffffffc7 !important',
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2.5rem', '2.5rem', '2.75rem', '2.75rem', '3rem'],
      color: 'primary',
      marginBottom: ['', '', '', '0rem']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'tertiary',
      marginBottom: ['', '', '', '0rem']
    },
    '.text': {
      variant: 'customVariants.text',
      p: {
        color: 'white',
        textAlign: 'left'
      }
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageHeroShout: {
    display: 'none'
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
    // backgroundColor: 'primary',
    '.title': {
      variant: 'customVariants.title',
      color: 'black',
      textShadow: '-5px -5px 0 #fdfdfd, 5px -5px 0 #fdfdfd, -5px 5px 0 #fdfdfd, 5px 5px 0 #fdfdfd'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      // color: 'white',
      color: 'tertiary'
    },
    '.text': {
      color: 'white',
      p: {
        color: 'white'
      }
    }
  },

  homepageMenu: {
    variant: 'customVariants.centerBlock',

    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'tertiary'
    }
  },
  pdfMenu: {
    variant: 'customVariants.centerBlock',
    '.container': {
      height: 'auto',
      minHeight: 'none',
      padding: 0
    },
    padding: 0,
    minHeight: 'none',
    height: 'auto'
  },
  homepagePress: {
    variant: 'customVariants.sideBySide1',
    padding: '0rem',
    backgroundColor: 'black',
    'div.content': {
      order: '2',
      padding: ['1rem', '1rem', '1rem'],
      '.title': {
        color: 'secondary'
      },
      '.subtitle': {
        color: 'tertiary'
      }
    },
    '.lazyload-wrapper': {
      img: {
        objectFit: 'contain'
      }
    }
  },
  homepageEvents: {
    variant: 'customVariants.sideBySide1',
    // backgroundColor: '',
    backgroundImage: 'url(https://res.cloudinary.com/gonation/image/upload/sites/zest/halftone.png)',
    backgroundSize: '90%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right center',
    color: 'white',
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'tertiary'
    },
    p: {
      color: 'white'
    },
    '.ctaButton': {
      backgroundColor: 'primary',
      color: 'white',
      ':hover': {
        backgroundColor: 'black'
      }
    }
  },

  homepageText: {
    variant: 'customVariants.centerBlock',
    '.section': {
      margin: 'auto auto auto 1rem',
      backgroundColor: '#0000008c',
      padding: '2rem',
      maxWidth: ['', '', '', '50%'],
      '.title': {
        textAlign: 'left',
        fontSize: ['1.25rem', '1.5rem', '2rem', '2.5rem'],
        color: 'white',
        textShadow: 'none'
      },
      '.subtitle': {
        textAlign: 'left',
        color: 'secondary',
        alignSelf: 'flex-start',
        order: '2',
        marginBottom: '0rem'
      }
    }
  },
  homepageHours: {
    variant: 'customVariants.centerBlock',
    borderTop: '10px solid',
    borderBottom: '10px solid',
    borderColor: 'primary',
    background: 'none',
    backgroundColor: '#1ca5a1',
    color: 'black',
    height: 'auto',
    padding: ['3rem 1rem', '3rem 1rem', '3rem 1rem', '3rem 1rem'],
    minHeight: 'unset',
    '.section': {
      border: 'solid 10px',
      borderColor: 'white',
      padding: '2rem',
      margin: ['', '', '0rem auto 0rem 3rem'],
      '.title': {
        // color: 'white',
      },
      '.subtitle': {
        order: '2',
        color: 'tertiary'
      },
      '.text': {
        order: '3',
        color: 'white',
        p: {
          color: 'white'
        }
      },
      a: {
        order: '4'
      }
    },
    backgroundImage:
      'url(https://res.cloudinary.com/gonation/image/upload/v1641304554/sites/zest/halftone.png)!important',
    backgroundSize: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right center'
  },

  homepageGallery: {
    variant: 'customVariants.centerBlock',
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'tertiary'
    }
  },

  homepageContact: {
    variant: 'customVariants.centerBlock'
  },

  homepageShout: {
    backgroundColor: 'black',
    backgroundImage: 'url(https://res.cloudinary.com/gonation/image/upload/v1641304554/sites/zest/halftone.png)',
    backgroundSize: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left center',
    marginBottom: '3rem',
    padding: ['1rem', '', '2rem 1rem', '3rem 2rem 10rem'],
    flexWrap: ['wrap', 'wrap', 'wrap', 'wrap'],
    margin: '0rem 0rem',
    '.title': {
      variant: 'customVariants.title',
      borderBottom: '3px solid',
      borderColor: 'tertiary',
      fontFamily: 'heading',
      width: ['', '', '80%'],
      color: 'black',
      backgroundColor: 'transparent'
    },
    '.text': {
      fontFamily: 'heading',
      fontSize: ['1.25rem', '1.5rem', '2rem', '2.25rem'],
      order: ['4', '', '3'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'black'],
      fontWeight: 'normal',
      color: 'white',
      padding: ['', '', '0rem 2rem'],
      backgroundColor: ['#ec008bc2'],
      textTransform: 'uppercase',
      padding: ['2rem', '2rem'],
      marginTop: '3rem',
      marginRight: ['', '', '1rem'],
      width: ['', '', '55%'],
      whiteSpace: 'break-spaces'
    },
    '.date': {
      backgroundColor: 'primary',
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontSize: '0.8rem',
      maxWidth: 'unset',
      color: 'white',
      flexGrow: '1',
      fontWeight: 'bold',
      justifyContent: 'center',
      position: 'relative',
      left: ['', '', '', '-3rem'],
      bottom: ['', '', '', '-2rem'],
      display: 'none'
    },
    '.imageContainer': {
      position: 'relative',
      top: ['', '', '', '5rem'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      order: ['', '', '4'],
      cursor: 'pointer',
      flexGrow: '1',
      width: ['', '', '40%'],
      '.imageFill': {
        paddingBottom: ['130%']
      },
      img: {
        objectPosition: 'top'
      }
    },
    '.shoutCTABtns ': {
      border: ['', '', '', 'solid 8px'],
      borderColor: ['', '', '', 'background'],
      flexDirection: ['row', 'row', 'row', 'column'],
      width: ['100%', '', '', 'auto'],
      a: {
        width: ['50%', '', '', '100%'],
        writingMode: ['unset', 'unset', 'unset', 'tb'],
        padding: '1rem',
        backgroundColor: 'transparent',
        color: 'primary',
        fontFamily: 'sans-serif',
        fontWeight: 'bold',
        ':hover': {
          backgroundColor: 'primary',
          color: 'white'
        }
      }
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      variant: 'customVariants.title',
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  bio: {
    variant: 'customVariants.sideBySide1',
    marginTop: '0rem',
    backgroundColor: '#e9e9e2',
    padding: '5rem 2rem 0rem',
    'div.content': {
      width: ['', '', '60%']
    },

    'div.lazyload-wrapper': {
      overflow: 'hidden',
      width: ['', '', '40%'],
      img: {
        objectFit: 'contain',
        objectPosition: 'top'
      }
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  // menu: {},
  menu: {
    '.menuSectionTitle': {
      variant: 'customVariants.title',
      fontSize: ['1.5rem', '1.75rem', '2.5rem', '2.5rem', '2.5rem'],
      textAlign: 'left',
      marginBottom: '2rem',
      backgroundColor: 'primary',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      textShadow: '-2px -2px 0 #fdfdfd, 2px -2px 0 #fdfdfd, -2px 2px 0 #fdfdfd, 2px 2px 0 #fdfdfd',
      border: 'solid 10px hsl(0deg 0% 95%)',
      borderRadius: '255px 15px 225px 15px/15px 225px 15px 255px'
    },

    '.menuSectionDescription': {
      textAlign: 'left',
      margin: '0rem 1rem 1.5rem',
      maxWidth: ['100%', '75%']
    },

    '.menuItemContainerImgActive': {
      height: '100%',
      '.menuItemInnerContainer': {
        flexDirection: ['column', '', 'row'],
        justifyContent: 'center',
        alignItems: 'center',
        '.menuItemContentContainer': {
          width: ['100%', '', '', '70%'],
          color: 'white',
          '.menuItemName': {
            variant: 'customVariants.title',
            fontSize: ['1.25rem', '1.5rem', '1.5rem', '1.5rem', '1.5rem'],
            // width: ['60%', '75%'],
            textShadow: 'none',
            marginBottom: '0rem',
            color: '#20cfca'
          },
          '.itemVariantsContainer': {
            display: 'flex',
            flexWrap: 'wrap'
          },
          '.variantsContainer': {
            display: 'flex',
            flexWrap: 'wrap',
            flexGrow: '1',
            margin: '0.5rem 1rem 0.5rem 0rem'
            // width: ['33.3%'],
          },
          '.variantContainer': {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            fontFamily: 'body2',
            backgroundColor: '#efefefba',
            padding: '0.75rem',
            borderRadius: '10px'
          },
          '.menuItemPriceLabel': {
            fontWeight: 'bold',
            fontSize: '0.8rem',
            marginBottom: '0.25rem'
          },
          '.variantContainerFloatTopRight': {
            fontSize: ['1.25rem', '1.5rem', '2rem']
          },
          '.menuItemLabelOptions': {
            fontSize: '0.7rem',
            maxWidth: '350px'
          },
          '.menuItemPriceVariants': {
            fontSize: '0.7rem',
            maxWidth: '350px'
          },
          '.menuItemDescription': {
            width: '100%',
            // backgroundColor: 'white',
            opacity: '1',
            fontSize: '1rem',
            color: 'white',
            fontFamily: 'body',
            lineHeight: '1.5'
          },
          '.menuItemPrice': {
            color: '#20cfca'
          }
        },

        '.menuItemImageContainer': {
          width: ['100%', '', '', '30%'],

          '.menuItemImg': {
            // objectPosition: 'top',
          }
        }
      }
    },
    '.menuItemPageContent': {
      '.itemVariantsContainer': {
        display: 'flex',
        flexWrap: 'wrap'
      },
      '.variantsContainer': {
        display: 'flex',
        flexWrap: 'wrap',
        flexGrow: '1',
        margin: '0.5rem 1rem 0.5rem 0rem'
        // width: ['33.3%'],
      },
      '.variantContainer': {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'body2',
        backgroundColor: '#efefefba',
        padding: '0.75rem',
        borderRadius: '10px'
      },
      '.menuItemPriceLabel': {
        fontWeight: 'bold',
        fontSize: '0.8rem',
        marginBottom: '0.25rem'
      },
      '.variantContainerFloatTopRight': {
        fontSize: ['1.25rem', '1.5rem', '2rem']
      },
      '.menuItemLabelOptions': {
        fontSize: '0.7rem',
        maxWidth: '350px'
      },
      '.menuItemPriceVariants': {
        fontSize: '0.7rem',
        maxWidth: '350px'
      },
      '.menuItemDescription': {
        width: '100%',
        backgroundColor: 'white',
        opacity: '1',
        lineHeight: '1.7',
        padding: '1rem 0.25rem'
      }
    },
    '.menuItemButton': {
      display: 'none'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.albumName': {
      display: 'none'
    },
    '.albumPhotos': {
      gridGap: '0rem'
    },
    '.albumImage': {
      padding: '0rem'
    },
    '.albumsCTAActive': {
      textDecoration: 'none',
      opacity: '1'
    },
    '.albumsCTA': {
      opacity: '0.8'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',

    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      maxWidth: '1000px'
    },
    '.text': {
      color: 'white'
    },
    backgroundPosition: 'center center',
    color: 'white',
    backgroundColor: 'secondary',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto'
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem'
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: 'secondary',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.75rem', '1.75rem', '1.75rem', '1.75rem', '1.75rem'],
      alignItems: 'center',
      color: 'black',
      textShadow: [
        '-2.5px -2.5px 0 #fdfdfd, 2.5px -2.5px 0 #fdfdfd, -2.5px 2.5px 0 #fdfdfd, 2.5px 2.5px 0 #fdfdfd',
        '',
        '-2.5px -2.5px 0 #fdfdfd, 2.5px -2.5px 0 #fdfdfd, -2.5px 2.5px 0 #fdfdfd, 2.5px 2.5px 0 #fdfdfd'
      ]
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.boxesHeading': {
      display: 'none'
    },
    '.textContent': {
      backgroundColor: 'lightgrey'
    },
    '.imageContainer': {
      backgroundColor: 'white',
      img: {
        objectFit: 'contain',
        padding: '1rem'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  }
}
