export default {
  text: 'white',
  primary: '#582c83',
  secondary: '#ec008b',
  tertiary: '#fef200',
  quaternary: '#f5951e',
  background: 'black',
  backgroundSecondary: '#33cbc9',
  light: '#FFF',
  dark: '#020303'
}
